import React, { useState, useEffect } from 'react'
import { RichText } from 'prismic-reactjs'

import ProductTitleMobile from '../ProductTitleMobile'

import './styles.css'

const Variedades = ({ variedades = [] }) => variedades.map((variedad, index) => (
  <li key={`prod-${index}`}>
    <div className="producto__image">
      <img src={variedad?.variedad_image?.url} alt={variedad?.variedad_image?.alt} />
    </div>
    <RichText render={variedad?.variedad_name?.raw} />
  </li>
))

const VariedadesOffset = ({ variedades = [], title }) => {
  const num = variedades.length % 3
  if (!num) return null

  return [...Array(num).keys()].map((_, index) => <li key={`off-${index}`}>&nbsp;</li>)
}

const SliceVariedades = ({ data }) => (
  <section className="producto__section producto__section--variedades">
    <h2>{data?.primary?.variedades_title?.text || 'variedades'}</h2>
    <ul className="producto__variedades">
      <Variedades variedades={data?.items} />
      <VariedadesOffset title={data?.primary?.variedades_title?.text} variedades={data?.items} />
    </ul>
  </section>
)

const SliceTexto = ({ data }) => (
  <section className="producto__section">
    <RichText render={data?.primary?.producto_text?.raw} />
  </section>
)

const Plantaciones = ({ plantaciones = [] }) => plantaciones.map((plantacion, index) => (
  <li key={`plant-${index}`}>
    <RichText render={plantacion?.plantacion_title?.raw} />
    <RichText render={plantacion?.plantacion_description?.raw} />
  </li>
))

const SlicePlantacion = ({ data }) => (
  <section className="producto__section">
    <h3>Marcos de plantación</h3>
      <ul className="producto__plantacion">
        <Plantaciones plantaciones={data?.items} />
      </ul>
  </section>
)

const Slice = ({ slice }) => {
  if (slice?.slice_type === 'variedades') return <SliceVariedades data={slice} />
  if (slice?.slice_type === 'texto') return <SliceTexto data={slice} />
  return <SlicePlantacion data={slice} />
}

const Slices = ({ slices = [] }) => slices.map((slice, index) => <Slice key={`slice-${index}`} {...{ slice }} />)

const ProductoContent = ({ title, data }) => {
  const [isVisible, setIsVisible] = useState(false)

  useEffect(() => {
    let showContent = setTimeout(() => {
      setIsVisible(true)
    }, 150);

    return () => clearTimeout(showContent)
  }, [])

  const getAnimationClass = () => {
    if (isVisible) return 'producto producto--visible content'
    return 'producto content'
  }

  return (
    <article className={getAnimationClass()}>
      <ProductTitleMobile {...{ title }} />
      <Slices slices={data?.body} />
    </article>
  )
}

export default ProductoContent