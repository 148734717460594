import React, { useState, useEffect } from 'react'
import { Link } from 'gatsby'

import { COLES_PAGE, LECHUGAS_PAGE, PRIMAVERA_PAGE, OTROS_PAGE } from '../../constants'

import productoColesImg from '../../images/servicios-coles.png' 
import productoLechugasImg from '../../images/servicios-lechugas.png' 
import productoPrimaveraImg from '../../images/servicios-primavera.png' 
import productoOtrosImg from '../../images/servicios-otros.png'

import SideMenu from '../SideMenu'

const Title = ({ active, children }) => {
  if (active) return <h1>{children}</h1>
  return <p>{children}</p>
}

const ProductoMenu = ({ pageName }) => {
  console.log('pageName',pageName)
  const [isVisible, setIsVisible] = useState(false)

  useEffect(() => {
    let showContent = setTimeout(() => {
      setIsVisible(true)
    }, 250);

    return () => clearTimeout(showContent)
  }, [])

  const getAnimationClass = () => {
    if (isVisible) return 'side-menu__items producto__menu producto__menu--visible'
    return 'side-menu__items producto__menu'
  }

  const getItemClassName = (active) => {
    if (active) return 'side-menu__active-item'
    return ''
  }

  const getImageClassName = (active) => {
    if (active) return 'side-menu__active-item__image'
    return 'side-menu__image'
  }

  return (
    <SideMenu>
      <ul className={getAnimationClass()}>
        <li className={getItemClassName(pageName === COLES_PAGE)}>
          <Link to="/coles">
            <Title active={pageName === COLES_PAGE}>coles</Title>
            <div className={getImageClassName(pageName === COLES_PAGE)}>
              <img src={productoColesImg} alt="coles" />
            </div>
          </Link>
        </li>
        <li className={getItemClassName(pageName === LECHUGAS_PAGE)}>
          <Link to="/lechugas">
            <Title active={pageName === LECHUGAS_PAGE}>lechugas</Title>
            <div className={getImageClassName(pageName === LECHUGAS_PAGE)}>
              <img src={productoLechugasImg} alt="lechugas" />
            </div>
          </Link>
        </li>
        <li className={getItemClassName(pageName === PRIMAVERA_PAGE)}>
          <Link to="/primavera">
            <Title active={pageName === PRIMAVERA_PAGE}>primavera</Title>
            <div className={getImageClassName(pageName === PRIMAVERA_PAGE)}>
              <img src={productoPrimaveraImg} alt="primavera" />
            </div>
          </Link>
        </li>
        <li className={getItemClassName(pageName === OTROS_PAGE)}>
          <Link to="/otros">
            <Title active={pageName === OTROS_PAGE}>otros</Title>
            <div className={getImageClassName(pageName === PRIMAVERA_PAGE)}>
              <img src={productoOtrosImg} alt="otros" />
            </div>
          </Link>
        </li>
      </ul>
    </SideMenu>
  )
}

export default ProductoMenu