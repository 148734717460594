import React from "react"

import Layout from "../components/Layout"
import SEO from "../components/SEO"
import ProductoContent from "../components/ProductoContent"
import ProductoMenu from "../components/ProductoMenu"
import DoctorPlantcanal from "../components/DoctorPlantcanal"

import '../styles/index.css'

const Producto = ({ location, pageContext: { data, uid } }) => {
  if (!data) return null

  return (
    <Layout pathname={location?.pathname}>
      <SEO title={data?.title?.text} />
      <div className="sidebar sidebar--left sidebar--hide-mobile">
        <ProductoMenu pageName={uid} />
      </div>
      <ProductoContent title={uid} {...{ data }} />
      <div className="sidebar sidebar--right sidebar--only-desktop">
        <DoctorPlantcanal />
      </div>
    </Layout>
  )
}

export default Producto
