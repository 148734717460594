import React from 'react'
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

import './styles.css'

const Image = ({ image }) => {
  if (!image) return null
  return (
    <div className="product-title-mobile__image">
      <Img fluid={image} fadeIn={true} />
    </div>
  )
}

const ProductTitleMobile = ({ title, image }) => {
  const data = useStaticQuery(graphql`
    query {
      coles: file(relativePath: { eq: "servicios-coles.png" }) {
        childImageSharp {
          fluid(maxWidth: 174) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      lechugas: file(relativePath: { eq: "servicios-lechugas.png" }) {
        childImageSharp {
          fluid(maxWidth: 174) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      primavera: file(relativePath: { eq: "servicios-berenjena.png" }) {
        childImageSharp {
          fluid(maxWidth: 174) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      otros: file(relativePath: { eq: "servicios-cebolla.png" }) {
        childImageSharp {
          fluid(maxWidth: 174) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  `)

  return (
    <header className="product-title-mobile">
      <h1>{title}</h1>
      <Image image={data?.[title]?.childImageSharp?.fluid} />
    </header>
  )
}

export default ProductTitleMobile